import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import {getData, postData} from './../Utils'


class RentalPoint extends Component {
    constructor(props) {
        super(props);
        this.state = { 

        }
        const { match: { params } } = this.props;
        if (params.id) {this.id = params.id}
        this.onChangeValue = this.onChangeValue.bind(this)
        this.onMapClick = this.onMapClick.bind(this)
    }

    componentDidMount(){
      if (this.id){
        getData('rentalpoint/'+this.id).then( point =>{
            console.log(point)
            this.setState(point)
            this.setState({map_center: {lat:point.lat, lng:point.lng}})
            } )
      }
      else {
          this.setState({name:"", lng:0, lat:0, address:"",price_index:0})
          this.setState({map_center: {lat:59.43, lng:24.75}})
      }
    }

    onChangeValue(event){
        this.setState({[event.target.name]: event.target.value})
    }
    save(){
      console.log(this.state)
      postData('rentalpoints',this.state).then( r=>{
        console.log(r)
        window.location.href = '/points'
      })
    }

    onMapClick(event){
      const lng = event.latLng.lng()
      const lat = event.latLng.lat()
      this.setState({lat, lng})
    }
    render() { 
        return (
           <React.Fragment>
            <Form>
              <FormGroup>
                <Label>Name</Label>
                <Input type="text" name="name" value={this.state.name || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
              <FormGroup>
                <Label>Address</Label>
                <Input type="text" name="address" value={this.state.address || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
              <FormGroup>
                <Label>Code</Label>
                <Input type="text" name="code" value={this.state.code || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
              <FormGroup>
                <Label>Terminal Code</Label>
                <Input type="text" name="terminal_id" value={this.state.terminal_id || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
              <FormGroup>
                <Label>Long</Label>
                <Input type="text" name="lng" value={this.state.lng || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
              <FormGroup>
                <Label>Lat</Label>
                <Input type="text" name="lat" value={this.state.lat || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
              <FormGroup>
                <Label>Price index</Label>
                <Input type="text" name="price_index" value={this.state.price_index || ''} onChange = {this.onChangeValue}/>
              </FormGroup>
        
              <Button color="primary" onClick={() => this.save()}>Save</Button>
            </Form>
            <div style={{ width: "800px", height: "400px" }}>
            { this.state.map_center && 
            <MapWrapped
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCORyz1O74CucN_aMVe0XVD-6Lv3QE8vvE`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              lat = {parseFloat(this.state.lat)} lng= {parseFloat(this.state.lng)}
              start_lat = {parseFloat(this.state.map_center.lat)} start_lng = {parseFloat(this.state.map_center.lng)}
              onMarkerDragEnd = {this.onMarkerDragEnd}
              onMapClick = {this.onMapClick}

            />
            }
            </div>
          </React.Fragment>
          );
    }
}
 

function Map(props) {
  const lat = props.lat
  const lng = props.lng
  const start_lat = props.start_lat
  const start_lng = props.start_lng
  const onMapClick = props.onMapClick
  return (
    <GoogleMap defaultZoom={14} defaultCenter={{ lat: start_lat, lng: start_lng }} onClick={onMapClick}>
      <Marker position = {{ lat: lat, lng: lng }}/>
    </GoogleMap>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default RentalPoint;