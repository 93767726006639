import React, { Component } from 'react';
import { Table,Button } from 'reactstrap';
import DeleteModal from '../Utils';
import {getData, delData} from './../Utils'

class RentalPoints extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            points : [],
            modal:false,
            todelete:0
         }
    this.pressNew = this.pressNew.bind(this)
    this.toggle = this.toggle.bind(this)
    this.confirm = this.confirm.bind(this)
    this.modiffyPressed = this.modiffyPressed.bind(this)
    }
    componentDidMount(){
        getData('rentalpoints').then(points =>{
            console.log(points)
            this.setState({points})
        })
    }
    pressNew(){
        this.props.history.push('/point')
    }
    modiffyPressed(id){ 
        this.props.history.push('/point/'+id)
    }
    toggle(id){
        const {modal} = this.state
        this.setState({to_delete:id})
        this.setState({modal:!modal})
    }
    confirm(){
        console.log(this.state.to_delete)
        this.setState({modal:false})
        delData('rentalpoint/'+this.state.to_delete).then( r=> {
            const points = this.state.points.filter(p => p.id !== this.state.to_delete)
            this.setState({points})
        })
    }
    render() {
        const rows = this.state.points.map(point => 
            <tr key={point.id}>
                <th>{point.name}</th><th>{point.address}</th>
                <th><Button style={{margin:"2px"}} color="primary" onClick={ () => this.modiffyPressed(point.id)}>Modify</Button>
                <Button color="danger" onClick={() => this.toggle(point.id)} style={{margin:"2px"}}>Delete</Button></th>
            </tr>)
        return (<React.Fragment>
            <Button color="primary" onClick={this.pressNew}>Add New</Button>
            <Table striped hover>
                <thead >
                    <tr>
                        <th>Name</th><th>Address</th><th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
            <DeleteModal modal={this.state.modal} toggle={this.toggle} confirm={this.confirm} />
            </React.Fragment>  );
    }
}
 
export default RentalPoints;