import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getData, postData } from "./../Utils";
class Type extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterTypes: [],
    };
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.id = params.id;
    }
    this.onChangeValue = this.onChangeValue.bind(this);
    this.save = this.save.bind(this);
  }
  componentDidMount() {
    getData("types/master").then((masterTypes) => {
      console.log(masterTypes);
      this.setState({ masterTypes });
    });

    if (this.id) {
      getData("type/" + this.id).then((type) => {
        console.log(type);
        this.setState(type);
      });
    } else {
      this.setState({ name: "", price_start: 0, price_hour: 0, price_24hour: 0, relocation_price: 0, master_type_id: 1, description: "" });
    }
  }
  onChangeValue(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  save() {
    postData("types", this.state).then((r) => {
      console.log(r);
      window.location.href = "/types";
    });
  }
  render() {
    const masterTypes = this.state.masterTypes.map((type) => (
      <option key={type.id} value={type.id}>
        {type.name}
      </option>
    ));
    return (
      <Form>
        <FormGroup>
          <Label>Type</Label>
          <Input type="select" name="master_type_id" value={this.state.master_type_id} onChange={this.onChangeValue}>
            {masterTypes}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Name</Label>
          <Input type="text" name="name" value={this.state.name || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Starting price</Label>
          <Input type="text" name="price_start" value={this.state.price_start || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Price per hour</Label>
          <Input type="text" name="price_hour" value={this.state.price_hour || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Price for first 24 hours</Label>
          <Input type="text" name="price_24hour" value={this.state.price_24hour || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Relocation Price</Label>
          <Input type="text" name="relocation_price" value={this.state.relocation_price || 0} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input type="textarea" name="description" value={this.state.description || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <Button color="primary" onClick={() => this.save()}>
          Save
        </Button>
      </Form>
    );
  }
}

export default Type;
