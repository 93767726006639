import React, {  } from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { getData } from '../Utils';

const comm ={
    question_title: "tile",
    answer_title: "answer"
}
export default function Comments(props){
   const commnets = props.comments.map(com => <Comment key={com.id} comment={com}/>)
return(<div className="row">{commnets}</div>)
}


function Comment(props){
    const [photos, setPhotos] = useState([])
    const {comment} = props
    useEffect(() => {
      getData(`trailer/${comment.trailer_id}/questions/${comment.id}/photos`).then( photos => {setPhotos(photos) })
    }, [])
    const cardParms=comment.answer_type == 'Negative' && comment.resolved != true ? {body:true, inverse:true, color:"danger"}: {body:true}
    const images = photos.map(p =>
        <div style={{width:"100%",margin:"2px"}}>
        <img alt="preview" size="50%" className="treiler-image-preview" src={p}/>
        </div>
        )
    console.log(images)
    return(
        <div style={{width:"100%",margin:"10px"}}>
        <Card {...cardParms}>
          <CardTitle className="row justify-content-between"><div><strong>{comment.question_title}</strong></div><div>{comment.left_at}</div></CardTitle>
          <CardText>{comment.answer_title}</CardText>
          <CardText>comment: {comment.comment}</CardText>
          {comment.answer_type == 'Negative' && <CardText>Resolved: {comment.resolved} {comment.resolved_at} {comment.resolved_by}</CardText>}
          {images}
        </Card>
      </div>
    )
}