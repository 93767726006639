import React from 'react';
import { Button, Table } from 'reactstrap';
import {getData,postData} from './../Utils'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useGlobalState} from './../global'

function Operators() {
    const [state, dispatch] = useGlobalState(); 
    const [operators, setOperators] = useState([])
    const [rows, setRows] = useState("")
    const history = useHistory();

    useEffect( () => {
        getData('operators').then(operators =>{
            console.log(operators)
            setOperators(operators)
        })
    }, []);

    function modiffyPressed(id){
        history.push('/operator/'+id)
    }
    function addAdminPressed(id){
        postData(`operator/${id}/setadmin`, {}).then(r => {
            const name = operators.filter(o => o.id ==id).map(o => o.name)[0]
            dispatch({message:`set as Admin for ${name}`})
        }).catch(error => {
                dispatch({error:`Error. Admin not set ${error}`})
            }
        )
        
    }
    function addNewPressed(){
        history.push('/operator/')  
    }
    useEffect( () =>{
        console.log(operators)
        const rows = operators.map(operator => 
            <tr key={operator.id}>
                <th>{operator.name}</th>
                <th>
                    <Button color="primary" onClick={() => modiffyPressed(operator.id)}>Modify</Button>&nbsp;
                    <Button color="primary" onClick={() => addAdminPressed(operator.id)}>Set me as Admin</Button>
                </th>
            </tr>)
        setRows(rows)
    }, [operators])

    return (<React.Fragment>
        <Button color="primary" onClick={addNewPressed}>Add New</Button>
        <Table striped hover>
            <thead >
                <tr>
                    <th>Name</th><th>Actions</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
        </React.Fragment>  
        );
}
export default Operators;