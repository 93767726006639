import React, { Component } from 'react';
import { Table,Input } from 'reactstrap';
import {getData, postData} from './../Utils'
import { Button } from 'aws-amplify-react';


class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = { devices:[], operators:[] }
        this.onChangeOwner = this.onChangeOwner.bind(this)
    }
    componentDidMount(){

        getData('operators').then( operators =>{
            console.log(operators)
            const my_operators = [{'id':null, 'name':'None'}].concat(operators)
            this.setState({operators:my_operators})
            }
        )
        getData('devices').then(devices =>{
            console.log(devices)
            this.setState({devices})
        })
    }

    deviceRefresh(){
        getData('devices_refresh').then( r=>{
            window.location = '/devices'
        })
    }
    onChangeOwner(event){
        console.log(event.target.name)
        console.log(event.target.value)
        const name = event.target.name
        const value = event.target.value
        const data = {serial:name, owner_id:value}
        postData('devices',data)
        const devices  = this.state.devices.map(device => {
            if (device.serial === name) {
                return {serial:name, owner_id:value}
            }
            else{
                return device
            }
        })
        console.log(devices)
        this.setState({devices})


    }
    render() { 
        const operators = this.state.operators.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
        const rows = this.state.devices.map(i => <tr key={i.serial}><th>{i.serial}</th>
        <th>{i.mac}</th>
        <th>{i.fw_version}</th>
        <th>{i.hw_version}</th>
        <th>{i.internal_battery}</th>
        <th>
        <Input type="select" name={i.serial} value = {i.owner_id} onChange = {this.onChangeOwner} >
                  {operators}
        </Input>
        </th>
        </tr>)
        return (
        <React.Fragment>
            <Button color="secondary" onClick={this.deviceRefresh}>refresh from Noke</Button>
            <Table>
                <thead>
                    <tr><th>Device ID</th><th>Mac</th><th>FW</th><th>HW</th><th>int bat</th><th>Operator</th></tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </React.Fragment>);
    }
}
 
export default Devices;