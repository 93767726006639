import React, { Component,useState,useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {useGlobalState} from './../global'
import { postData, getData } from '../Utils';
import { useHistory, useParams } from 'react-router-dom';


function User() {
  const history = useHistory()
  const params = useParams()
  const [state, dispatch] = useGlobalState();
  const [points, setPoints] = useState([])
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [pointSelect, setPointsSelect] = useState('')
  const [mypoints, setMypoints] = useState([])

  useEffect( () => {
    getData('rentalpoints').then(points => {
      console.log(points)
      setPoints(points)
      if(params.id){
        getData(`users/${params.id}`).then( user => {
          setEmail(user.email)
          setName(user.name)
          setMypoints(user.points)
        })
      }
    })

  }, []); 

  useEffect( () => {
    const pointSelect = points.map(point => <option key={point.id} id={point.id} value={point.id}>{point.name}</option>)
    setPointsSelect(pointSelect)
  }, [points]); 

function updateName(event){
  setName(event.target.value)
}
function updateAdress(event){
  setEmail(event.target.value)
}
function upDatePoints(event){
  console.log(event.target.selectedOptions)
  const points = Array.from(event.target.selectedOptions, option => option.value)
  setMypoints(points)

}
function save(){
  let obj = {
    email: email,
    name: name,
    points: mypoints
    }
  if(params.id){
    obj.id = params.id
  }
  postData('users', obj).then( r => {
    dispatch({message:`${name} user mainipulation success`})
    history.push('/users')
    }
  )
}
  return (
    <React.Fragment>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input type="text" name="name" value={name} onChange = {updateName}/>
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input type="text" name="email" value={email} onChange = {updateAdress}/>
          </FormGroup>  
          <FormGroup>
            <Label >Select Rentail Points</Label>
            <Input type="select" value={mypoints} name="point" multiple onChange={upDatePoints}>
              {pointSelect}
            </Input>
          </FormGroup>
        </Form>
        <Button color="primary" onClick={save}>Save</Button>
    </React.Fragment>  );

}


 
export default User;