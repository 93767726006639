import React, { Component } from "react";
import { Table, Button } from "reactstrap";
import DeleteModal from "../Utils";
import { getData, delData, DynTable } from "./../Utils";

class Treilers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treilers: [],
      modal: false,
      to_delete: 0,
      is_admin: false,
    };
    this.addNewPressed = this.addNewPressed.bind(this);
    this.toggle = this.toggle.bind(this);
    this.confirm = this.confirm.bind(this);
  }
  componentDidMount() {
    getData("trailers").then((treilers) => {
      console.log(treilers);
      getData("types").then((types) => {
        treilers.map((tr) => {
          try {
            const type = tr.trailer_type && tr.trailer_type !== 0 ? types.filter((ty) => ty.id == tr.trailer_type)[0].name : tr.trailer_type;
            tr.type_str = type ? type : tr.trailer_type;
          } catch (error) {
            console.log(error);
            console.log(tr.id);
            tr.type_str = tr.trailer_type;
          }
          return tr;
        });
        this.setState({ treilers });
      });
    });
    getData("self").then((me) => {
      const is_admin = me.type === "administrator" ? true : false;
      this.setState({ is_admin });
    });
  }
  addNewPressed() {
    this.props.history.push("/treiler");
  }
  modiffyPressed(id) {
    this.props.history.push("/treiler/" + id);
  }
  toggle(to_delete) {
    const { modal } = this.state;
    this.setState({ to_delete });
    this.setState({ modal: !modal });
  }
  confirm() {
    console.log(this.state.to_delete);
    this.setState({ modal: false });
    delData("trailer/" + this.state.to_delete).then((r) => {
      const treilers = this.state.treilers.filter((t) => t.id !== this.state.to_delete);
      this.setState({ treilers });
    });
  }
  render() {
    const toggle = this.toggle;
    const { treilers, modal, is_admin } = this.state;
    return (
      <React.Fragment>
        <Button color="primary" onClick={this.addNewPressed}>
          Add New
        </Button>
        <DrawDynamicTrailersTable treilers={treilers} toggle={toggle} is_admin={is_admin} />
        <DeleteModal modal={modal} toggle={toggle} confirm={this.confirm} />
      </React.Fragment>
    );
  }
}

function DrawDynamicTrailersTable(props) {
  const columns = [
    {
      Header: "Trailers",
      columns: [
        {
          Header: "Reg NR",
          accessor: "reg_number",
          sortType: "basic",
        },
        {
          Header: "State",
          accessor: "active_m",
          sortType: "basic",
        },
        {
          Header: "Rentail point",
          accessor: "point_name",
          sortType: "basic",
        },
        {
          Header: "Dock id",
          accessor: "dock_id",
          sortType: "basic",
        },
        {
          Header: "Type",
          accessor: "type_str",
          sortType: "basic",
        },
        {
          Header: "Renter Info",
          accessor: "renter_info",
        },
        {
          Header: "Last Issue",
          accessor: "last_negative",
        },
        {
          Header: "Actions",
          accessor: "actions",
        },
      ],
    },
  ];
  const data = props.treilers;
  const { toggle, is_admin } = props;
  data.map((d) => {
    d.actions = (
      <React.Fragment>
        <a href={`/treiler/${d.id}`}>
          <Button color="primary" style={{ margin: "2px" }}>
            Details
          </Button>
        </a>{" "}
        {is_admin && (
          <Button color="danger" style={{ margin: "2px" }} onClick={() => toggle(d.id)}>
            Delete
          </Button>
        )}
      </React.Fragment>
    );
    d.device_serial_m = d.device_serial != 0 && d.device_serial;
    d.active_m = (d.active && d.status) || "inactive";
    d.renter_info =
      d.status == "inrent" ? (
        <div>
          <div>{(d.renter_info && d.renter_info.email) || ""}</div>
          <div>{(d.session_info && d.session_info.started_at) || ""}</div>
        </div>
      ) : (
        "N/A"
      );
    d.last_negative = d.last_negative ? (d.last_negative.resolved == false ? d.last_negative.question_title : "") : "";
    return d;
  });
  return <DynTable columns={columns} data={data} />;
}
export default Treilers;
