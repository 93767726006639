import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import {getData, postData} from './../Utils'


class Types extends Component {
    constructor(props) {
        super(props);
        this.state = { types:[] }
        this.addNewPressed = this.addNewPressed.bind(this)
        this.modiffyPressed = this.modiffyPressed.bind(this)
    }
    componentDidMount(){
        getData('types').then(types =>{
            console.log(types)
            this.setState({types})
        })
    }

    addNewPressed(){
        this.props.history.push('/type/')
    }

    modiffyPressed(id){ 
        this.props.history.push('/type/'+id)
    }
    render() { 
        const rows = this.state.types.map(type => 
            <tr key={type.id}>
                <th>{type.name}</th>
                <th>
                    <Button color="primary" onClick={() => this.modiffyPressed(type.id)}>Modify</Button>
                    {" "}
                    <Button color="danger">Disable</Button>
                </th>
            </tr>)
        return (<React.Fragment>
            <Button color="primary" onClick={this.addNewPressed}>Add New</Button>
            <Table striped hover>
                <thead >
                    <tr>
                        <th>Name</th><th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
            </React.Fragment>  );
    }
}
 
export default Types;