import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { getData,postData } from '../Utils';
import { useGlobalState } from '../global';

export default function Settings() {
    const [state, dispatch] = useGlobalState()
    const [payment_key, setPayment_key ]= useState('')
    useEffect( () => {
        getData('self').then(me => {
            setPayment_key(me.payment_key)
        })
    },[])
    function onChangeKey(e){
        setPayment_key(e.target.value)
    }
    function save(){
        postData('self',{payment_key}).then(r => {
            dispatch({message: 'Save successful'})
        }).catch( error => {
            dispatch({error:`Error. not saved ${error}`})
        })
    }
        
    return (  
        <Form>
            <FormGroup>
                <Label>Payment key</Label>
                <Input type="text" name="payment_key"  value={payment_key} onChange={onChangeKey}/>
            </FormGroup>
            <Button color="primary" onClick={save} >Save</Button>
        </Form>
    ); 
}

class Settings_c extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    this.onChangeValue = this.onChangeValue.bind(this)
    }
    onChangeValue(event){
        this.setState({[event.target.name]: event.target.value})
    }
    componentDidMount(){
        const payment_key = "123-AVD-KKK-nfdsafsd-333"
        this.setState({payment_key})
    }
    render() { 
        const payment_key = this.state.payment_key || ''
        return (  
            <Form>
                <FormGroup>
                    <Label>Payment key</Label>
                    <Input type="text" name="payment_key"  value={payment_key} onChange={this.onChangeValue}/>
                </FormGroup>
                <Button color="primary">Save</Button>
            </Form>
        );
    }
}
 
