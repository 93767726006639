import React, { Component, useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getData, postData } from "../Utils";
import { useHistory, useParams } from "react-router-dom";

export default function Dock() {
  const params = useParams();
  const history = useHistory();
  const [dock, setDock] = useState({});
  const [points, SetPoints] = useState([]);
  const [devices, SetDevices] = useState([]);

  useEffect(() => {
    getData("rentalpoints").then((points) => {
      points.unshift({ id: 0, name: "None" });
      console.log(points);
      SetPoints(points);
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      getData(`dock/${params.id}`).then((dock) => setDock(dock));
    }
  }, []);
  useEffect(() => {
    let url = "operator_devices/?type=dock";
    url = params.id ? `${url}&id=${params.id}` : url;
    getData(url).then((devices) => {
      let myDevices = devices.map((t) => {
        return { id: t.serial, name: t.serial };
      });
      myDevices.unshift({ serial: "None", name: "None" });
      SetDevices(myDevices);
    });
  }, []);
  const onChangeValue = (event) => {
    setDock({ ...dock, [event.target.name]: event.target.value });
  };
  const onChangeValueBoolean = (event) => {
    setDock({ ...dock, [event.target.name]: event.target.value === "true" });
  };
  const save = () => {
    const data = params.id ? { ...dock, id: params.id } : dock;
    postData("docks", data).then((r) => history.push("/docks"));
  };
  return (
    <>
      <Form>
        <FormGroup>
          <Label>Select Rental Point</Label>
          <Input type="select" name="rental_point_id" value={dock.rental_point_id} onChange={onChangeValue}>
            {points.map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Attached Device</Label>
          <Input type="select" name="device_serial" value={dock.device_serial} onChange={onChangeValue}>
            {devices.map((d) => (
              <option key={d.serial} value={d.serial}>
                {d.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Dock Id</Label>
          <Input type="text" name="dock_id" value={dock.dock_id} onChange={onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Comment</Label>
          <Input type="text" name="comment" value={dock.comment} onChange={onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Active</Label>
          <Input type="select" name="active" value={dock.active} onChange={onChangeValueBoolean}>
            <option key={"true"} value={true}>
              True
            </option>
            <option key={"false"} value={false}>
              False
            </option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Requires Maintenance</Label>
          <Input type="select" name="requires_maintenance" value={dock.requires_maintenance} onChange={onChangeValueBoolean}>
            <option key={"false"} value={false}>
              False
            </option>
            <option key={"true"} value={true}>
              True
            </option>
          </Input>
        </FormGroup>
        <Button color="primary" onClick={() => save()}>
          Save
        </Button>
      </Form>
    </>
  );
}
