import React, {  } from 'react';
import { Table, Button} from 'reactstrap';
import { postData } from '../Utils';
import { useGlobalState } from '../global';
import { useState } from 'react';


export default function CommentsTable(props){
    const rows = props.comments.map(com => <Row  key={com.id} comment={com}/>)
    return(
        <Table striped hover>
            <thead >
                <tr>
                    <th>Question</th>
                    <th>at strat/end</th>
                    <th>Comment</th>
                    <th>Time</th>
                    <th>resolved</th>
                    <th>session</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    )

}

function Row(props){
    const [state, dispatch]  = useGlobalState()
    const [comment, setComment] = useState(props.comment)
    function resolve(){
        postData(`questions/${comment.id}/resolve`, {}).then(r => {
            setComment({...comment, resolved:true, resolved_at: new Date().toLocaleString()})
            dispatch({message: "Comment resolved"})
        }).catch(error => {
                dispatch({error:`Error resolving the comment: ${error}`})
            }
        )
    }
    console.log(comment)
    return (
        <tr>
            <td>{comment.question_title}</td>
            <td>{comment.left_at}</td>
            <td>{comment.comment}</td>
            <td>{comment.created_at}</td>
            <td>{comment.resolved && (comment.resolved_at || "") || <Button onClick={resolve}>Resolve</Button>}</td>
            <td>{comment.session_id && <a href={`/session/${comment.session_id}`}>{comment.session_id}</a>  }</td>
        </tr>
    )
}